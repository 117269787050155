.App {
  text-align: center; }

.App-logo {
  height: 80px; }

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white; }

.App-title {
  font-size: 1.5em; }

.App-intro {
  font-size: large; }

nav {
  background-color: #f5f5f5; }

main {
  padding: 30px; }

button {
  line-height: 1.3rem !important; }

.foods-container {
  min-height: 106px; }

.index-container {
  display: flex; }
  @media (max-width: 767px) {
    .index-container {
      flex-direction: column; } }

.switch-container {
  float: left;
  position: relative;
  top: -15px;
  align-self: flex-start;
  white-space: nowrap; }

.table {
  display: flex;
  flex-wrap: wrap;
  max-width: 650px;
  margin: auto;
  align-content: flex-start;
  min-width: 240px; }
  .table tr {
    height: auto; }
  .table th, .table td {
    font-size: 14px;
    padding: 12px 15px; }
    .table th:last-child, .table td:last-child {
      padding-right: 15px; }
    .table th:first-child, .table td:first-child {
      width: 200px; }
    .table th:not(:first-child), .table td:not(:first-child) {
      text-align: right; }
  .table th {
    font-weight: 500;
    background-color: #3f51b5;
    color: #fff;
    padding-top: 14px;
    padding-bottom: 14px;
    white-space: nowrap; }
  .table .head {
    margin-bottom: 5px;
    white-space: nowrap; }
  .table label {
    min-width: 150px; }
  .table--index {
    max-width: 365px;
    flex: 1 100%;
    width: 100%; }
    @media (min-width: 768px) {
      .table--index {
        position: relative;
        left: -65px; } }
    .table--index .head {
      margin-bottom: 10px; }
  .table--foods td:last-child {
    padding: 0;
    white-space: nowrap; }
  .table .break {
    width: 100%; }
  .table .cell {
    display: flex;
    justify-content: flex-end;
    text-align: right;
    flex: 1;
    padding: 2px 15px; }
    .table .cell input {
      width: 100%; }
  .table label {
    justify-content: flex-start !important;
    text-align: left !important; }
  .table .cell.action {
    padding: 0; }
  .table--add-edit {
    flex-wrap: nowrap;
    margin-top: 50px; }
    .table--add-edit > * {
      flex: 1; }
    .table--add-edit > :first-child {
      flex-basis: 16%; }
    .table--add-edit button {
      margin-top: -5px; }
    .table--add-edit .head {
      color: #fff;
      background-color: #3f51b5;
      padding-top: 14px;
      padding-bottom: 14px;
      min-height: 16px;
      font-size: 14px;
      font-weight: 500; }
    .table--add-edit .text-field {
      width: 100%; }

.highchart {
  margin: auto;
  flex: 1 100%;
  width: 100%; }
  .highchart .highcharts-root {
    font-family: Roboto !important; }
  .highchart .highcharts-axis-labels text {
    font-size: 13px !important; }
  .highchart .highcharts-legend-item text {
    font-size: 13px !important; }
    @media (max-width: 991px) {
      .highchart .highcharts-legend-item text {
        font-size: 14px !important; } }
  .highchart .highcharts-header {
    font-size: 12px !important; }
  .highchart .highcharts-legend-item .highcharts-point {
    display: none; }
  .highchart .highcharts-legend-box:not(.highcharts-shadow) {
    fill: rgba(255, 255, 255, 0.5); }
  .highchart .highcharts-legend-title {
    font-size: 16px;
    transform: translate(8px, 7px); }
    @media (max-width: 991px) {
      .highchart .highcharts-legend-title {
        transform: translate(15px, 12px);
        font-size: 18px !important; } }

.no-foods {
  padding: 20px 0;
  margin: auto; }
  .no-foods a, .no-foods button {
    margin-top: 10px; }

.spinner-container {
  min-height: 106px;
  display: flex;
  justify-content: center;
  align-items: center; }

.radio-group {
  max-width: 350px;
  margin: auto;
  margin-top: 30px;
  justify-content: center; }
  .radio-group label {
    margin-left: 5px;
    margin-right: 40px; }
    .radio-group label > span:last-child {
      position: relative;
      left: -2px; }
  .radio-group button {
    width: 35px;
    height: 35px;
    margin-left: -40px;
    margin-right: 5px;
    align-self: center;
    padding: 0; }

.popover {
  padding: 16px;
  font-size: 14px;
  cursor: default; }
  .popover p {
    margin: 0 0 5px; }
    .popover p:last-child {
      margin-bottom: 0; }
  .popover label {
    margin-bottom: -15px; }

.slider {
  max-width: 150px;
  margin: auto; }

.weights {
  display: flex;
  margin: auto;
  justify-content: space-between;
  max-width: 150px; }

.btn-signin {
  position: absolute !important;
  right: 15px; }

.modal-container {
  display: flex;
  justify-content: center;
  align-items: center; }

.modal {
  display: flex;
  flex-direction: column;
  margin: 0 15px;
  padding: 30px;
  max-width: 600px;
  line-height: 24px; }
  .modal h2 {
    margin-top: 0;
    font-weight: 500;
    align-self: flex-start;
    font-size: 21px; }
  .modal p {
    margin-top: 0;
    color: #777; }
  .modal img {
    width: 250px;
    cursor: pointer; }
    .modal img:first-of-type {
      margin-left: -3px;
      width: 256px; }
  .modal .cta {
    margin-top: 10px;
    display: flex;
    justify-content: flex-end; }
    .modal .cta button:not(:last-of-type) {
      margin-right: 10px; }
  .modal > :not(:last-child) {
    margin-bottom: 16px; }
  .modal:focus {
    outline: none; }

.async-button {
  display: inline-block;
  position: relative; }
  .async-button .spinner {
    width: 30px !important;
    height: 30px !important;
    top: 10px;
    left: 8px;
    position: absolute;
    display: none; }
  .async-button--loading .spinner {
    display: block; }
  .async-button--loading button {
    visibility: hidden; }

.action .spinner {
  top: 4px;
  left: 9px; }

.preload {
  display: block;
  width: 0;
  height: 0;
  visibility: hidden; }

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(360deg); } }

@media (max-width: 619px) {
  .table--foods th:not(:first-child):not(:last-child), .table--foods td:not(:first-child):not(:last-child) {
    display: none; }
  .table--add-edit {
    flex-direction: column; }
    .table--add-edit > *:first-child {
      width: 100%; }
    .table--add-edit > *:not(:last-child) {
      margin-bottom: 10px; }
    .table--add-edit > *:last-child {
      height: 0; }
      .table--add-edit > *:last-child .head {
        display: none; }
      .table--add-edit > *:last-child .cell {
        justify-content: flex-end;
        margin-top: 10px;
        margin-bottom: 15px; }
    .table--add-edit .cell {
      text-align: left;
      justify-content: flex-start; } }
